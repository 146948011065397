import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import AuthService from '../utils/AuthService';
import UploadImage from '../utils/UploadImage';
import {Accordion, AccordionItem} from 'react-sanfona';
import DatePicker from '../utils/DatePicker';
import {cleanCSVText, validUrl, convertTimeStampToHumanReadable, valueDoesExist, findFirstAndLastName} from '../utils/HelpfulFunction';
import ReactPlayer from "react-player";

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.UploadImage = new UploadImage();
        this.state = {
          ticketList: [],
          tenantVariables: {},
          questionsList: [],
          questionsAdded: [],
          emailsSent: {},
          question: 0,
          emailVariables: {},
          tenantRules: {},
          id_key: "",
          userAnswers:[],
          currentGameRewards: [],
          csvRewards: [],
          currentGameFormFilledList: {},
          currentGame: null,
          modal: false,
          timeLeft: 10,
          timeModal: false,
          userGameHistory: [],
          users: [],
          gameName: "",
          startTime: new Date(),
          endTime: new Date(),
          loading: true,
          wizardLocation: 'first',
          showStats: false,
          tierMessaging: [],
          itemsPerPage: 0,
          itemsToShow: 0,
          gameTiming: "immediate",
          advanceSettings: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.timeModalToggle = this.timeModalToggle.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
    }

    getCurrentTime(datePassedIn){
      if(!datePassedIn){
        datePassedIn = new Date();
      }
      return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
      const vm = this;
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.idKeyRef = base.fetch(`id_key`, {
        context: this,
        then(data){
          this.setState({
            id_key: data
          })
        }
      });
      this.emailVariablesRef = base.bindToState(`emailVariables`, {
        context: this,
        state: 'emailVariables',
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.rewardsListRef = base.syncState(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
      this.tierMessagingRef = base.syncState(`tierMessaging`, {
        context: this,
        state: 'tierMessaging',
        asArray: true
      });
      this.currentGameFormFilledRef = base.bindToState('currentGameFormFilled', {
        context: this,
        state: 'currentGameFormFilledList',
      });
      this.questionsListRef = base.bindToState('questionsList',{
        context: this,
        state: 'questionsList',
        asArray: true
      });
      this.emailsSentRef = appDatabasePrimaryFunctions.ref("emailsSent").on('value', function(snapshot) {
        vm.setState({
          emailsSent: snapshot.val()
        })
      })
      this.userAnswersRef = appDatabasePrimaryFunctions.ref("userAnswers").on('value', function(snapshot){
        vm.setState({
          userAnswerCount: snapshot.numChildren()
        })
      })
      this.currentGameRef = base.syncState(`currentGame`, {
        context: this,
        state: 'currentGame',
        then(err){
          if(!err){
            this.setGameToWatch(this.state.currentGame.id);
          }
        }
      });
      this.setState({loading:false})
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      if(id){
        const vm = this;
        this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
          vm.setState({
            userGameHistory: snapshot.numChildren()
          })
        })
      }
    }

    async downloadUsers(){
      this.setState({loading: true})
      let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Opt-In,Number of Correct Answers,Reward,Code,Link,Answer Time\n';
      if(this.props.isMacuTeam) {
        csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Opt-In,Number of Correct Answers,Reward,Code,Link,Answer Time,Auto Loans,Business Services,Credit Cards,Meeting with a Financial Guide,Mortgages,Retirement Savings,Other\n';
      }

      let users = await appDatabasePrimaryFunctions.ref("users").once('value').then(function(snapshot){
        return snapshot.val()
      });
      let answerList = await this.sortPlayersForAdmin();
      const vm = this;
      answerList[0].forEach(function(row) {
        let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
        csv += (users[row.uid].email || "");
        csv+= ",";
        csv+= (cleanCSVText(firstName) || "");
        csv+= ",";
        csv+= (cleanCSVText(secondName) || "");
        csv+= ",";
        csv+= (users[row.uid].phoneNumber || "");
        csv+= ",";
        csv+= (cleanCSVText(users[row.uid].zipCode) || "");
        csv+= ",";
        csv+= (users[row.uid].birthday || "");
        csv+= ",";
        csv+= (users[row.uid].optIn || "");
        csv+= ",";
        csv+= row.correctAnswers;
        csv+= ",";
        csv+= (cleanCSVText(row.rewardSent) || "NONE");
        csv+= ",";
        csv+= (cleanCSVText(row.code) || "-");
        csv+= ",";
        csv+= (cleanCSVText(row.link) || "-");
        csv+= ",";
        csv += convertTimeStampToHumanReadable(row.timeStamp);
        if(vm.props.isMacuTeam){
          csv+= ",";
          csv+= (users[row.uid].auto_loans ? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].business_services? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].credit_cards ? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].financial_guide ? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].mortgages ? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].retirement_savings ? "True" : "");
          csv+= ",";
          csv+= (users[row.uid].otherInput || "");
        }
        csv += "\n";
      });
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      hiddenElement.target = '_blank';
      let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
      hiddenElement.download = "users_" + date_got;
      hiddenElement.click();
      this.setState({loading: false})
    }

    componentWillUnmount() {
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.rewardsListRef);
      base.removeBinding(this.questionsListRef);
      base.removeBinding(this.currentGameRef);
      appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
      appDatabasePrimaryFunctions.ref('emailsSent').off('value', this.emailsSentRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.emailVariablesRef);
    }

    async createGame(){
      const createGameObject = {};
      const tenantVariables = this.state.tenantVariables;
      const gameId = appDatabasePrimaryFunctions.ref().push().key;
      const questionsAdded = this.state.questionsAdded;
      let gameName = this.state.gameName.trim();
      let startTime = this.state.startTime;
      let endTime = this.state.endTime;
      let showScheduleTime = this.state.showScheduleTime;
      let gameTiming = this.state.gameTiming;
      const videoLink = this.state.videoLink;
      if(gameTiming === "scheduled"){
        showScheduleTime = true
      }
      if(!gameName){
        swal({
            title: "Hold On!",
            text: 'The game needs a name!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return
      }
      const rewardList = []
      const messaging_array = []
      for(var i = 0; i < questionsAdded.length + 1; i++){
        var index = i;
        var tiermessaging = {}
        tiermessaging.number_correct = index;
        var winningHeader = "You win!";
        var winningMessage = "Check your email for your prize!";
        var winningMissedHeader = "Nice Job!";
        var winningMissedMessage = "You got " + index.toString() + " correct! Unfortunately you didn't win a prize but try again soon!"
        if(index > 0){
          winningMessage = "You got " + index.toString() + " correct! Check your email for your prize!"
        }
        if(this.state['winningWinnerHeader' + index.toString()] != undefined){
          winningHeader = this.state['winningWinnerHeader' + index.toString()]
        } else if(tenantVariables.globalNoneCorrectPrizeHeader && tenantVariables.globalNoneCorrectPrizeHeader.length > 0 && index === 0) {
          winningHeader = this.detectUseOfIndex(tenantVariables.globalNoneCorrectPrizeHeader, index)
        } else if (tenantVariables.globalPrizeWonHeader && tenantVariables.globalPrizeWonHeader.length > 0) {
          winningHeader = this.detectUseOfIndex(tenantVariables.globalPrizeWonHeader, index)
        }
        if(this.state['winningWinnerMessage' + index.toString()] != undefined){
          winningMessage = this.state['winningWinnerMessage' + index.toString()]
        } else if(tenantVariables.globalNoneCorrectPrizeBody && tenantVariables.globalNoneCorrectPrizeBody.length > 0 && index === 0) {
          winningMessage = this.detectUseOfIndex(tenantVariables.globalNoneCorrectPrizeBody, index)
        } else if (tenantVariables.globalPrizeWonBody && tenantVariables.globalPrizeWonBody.length > 0) {
          winningMessage = this.detectUseOfIndex(tenantVariables.globalPrizeWonBody, index)
        }
        if(this.state['winningMissedHeader' + index.toString()] != undefined){
          winningMissedHeader = this.state['winningMissedHeader' + index.toString()]
        } else if (tenantVariables.globalNoPrizeHeader && tenantVariables.globalNoPrizeHeader.length > 0) {
          winningMissedHeader = this.detectUseOfIndex(tenantVariables.globalNoPrizeHeader, index)
        }
        if(this.state['winningMissedMessage' + index.toString()] != undefined){
          winningMissedMessage = this.state['winningMissedMessage' + index.toString()]
        } else if (tenantVariables.globalNoPrizeBody && tenantVariables.globalNoPrizeBody.length > 0) {
          winningMissedMessage = this.detectUseOfIndex(tenantVariables.globalNoPrizeBody, index)
        }

        tiermessaging.winningHeader = winningHeader;
        tiermessaging.winningMessage = winningMessage;
        tiermessaging.winningMissedHeader = winningMissedHeader;
        tiermessaging.winningMissedMessage = winningMissedMessage;

        const rewardsAdded = this.state['rewardsAdded' + i.toString()] || [];
        if(rewardsAdded.length !== 0 && !this.state.noPrizes){
          let totalAmount = 0;
          const readyToUploadPrizes = {};
          for(const rewardIndex in rewardsAdded){
            const generatedRewardId = appDatabasePrimaryFunctions.ref().push().key;
            const rewardVars = Object.assign({}, rewardsAdded[rewardIndex]);
            readyToUploadPrizes[generatedRewardId] = Object.assign({}, rewardVars.reward);
            readyToUploadPrizes[generatedRewardId]['amount'] = rewardVars.amount || "0";
            totalAmount+=(parseInt(rewardVars.amount) || 0);
            readyToUploadPrizes[generatedRewardId]['codes'] = rewardVars.codes || false;
            readyToUploadPrizes[generatedRewardId]['allElsePrize'] = rewardVars.allElsePrize || false;
            readyToUploadPrizes[generatedRewardId]['codesArray'] = rewardVars.codesArray || [];
            readyToUploadPrizes[generatedRewardId]['linksArray'] = rewardVars.linksArray || [];
            readyToUploadPrizes[generatedRewardId]['pinsArray'] = rewardVars.pinsArray || [];
            readyToUploadPrizes[generatedRewardId]['used'] = 0;
          }
          readyToUploadPrizes["totalRewards"] = totalAmount;
          readyToUploadPrizes["totalRewardsUsed"] = 0;
          readyToUploadPrizes["randomPrizeOrder"] = this.state["randomOrder" + i.toString()] || false;
          readyToUploadPrizes["answerLevel"] = i;
          rewardList.push(readyToUploadPrizes);
        }
        messaging_array.push(tiermessaging)
      }
      if(questionsAdded.length === 0){
        swal({
            title: "Missing Question",
            text: 'Go to the second tab to add questions',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(rewardList.length === 0 && !this.state.noPrizes){
        swal({
            title: "Hold On!",
            text: 'You have no rewards added to the game! You need at least 1',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }

      if(showScheduleTime) {
        if(!startTime && gameTiming === "scheduled"){
          swal({
            title: "Hold On!",
            text: 'The game needs a start time',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
          return
        } else if(startTime.getTime() <= Date.now()){
          swal({
            title: "Hold On!",
            text: 'The start time cannot be before now',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
          return
        } else if(gameTiming === "scheduled" && !endTime) {
          swal({
            title: "Hold On!",
            text: 'The game needs an answer time if it is scheduled!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(gameTiming === "scheduled" && endTime.getTime() <= Date.now()) {
          swal({
            title: "Hold On!",
            text: 'A scheduled game cannot have an answer time that is in the past',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(gameTiming === "scheduled" && endTime.getTime() <= startTime.getTime()) {
          swal({
            title: "Hold On!",
            text: 'A answer time cannot be before start time',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(gameTiming === "manual"){
          endTime = null
        } else if(gameTiming === "immediate" && endTime){
          if(endTime <= Date.now()){
            endTime = null
          } else if (endTime.getTime() <= startTime.getTime()) {
            swal({
              title: "Hold On!",
              text: 'A end time cannot be before start time',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
            return
          }
        }
        if(startTime){
          startTime = startTime.getTime()/1000;
        }
        if(endTime){
          endTime = endTime.getTime()/1000;
        }
      } else {
        startTime = null
        endTime = null
      }

      createGameObject.scheduleInfo = {};
      createGameObject.scheduleInfo['status'] = 'scheduled';
      createGameObject.scheduleInfo['performAt'] = startTime;
      createGameObject.scheduleInfo['endAt'] = endTime;
      createGameObject.questions = questionsAdded;
      createGameObject.active = false;
      createGameObject.answered = false;
      createGameObject.rotate = false;
      createGameObject.rotationEnd = false;
      createGameObject.screenToShow = 0;
      createGameObject.questionToShow = 0;
      createGameObject.gameType = "trivia";
      createGameObject.rewards = rewardList;
      createGameObject.endTriviaVideoLink = videoLink || "";
      createGameObject.timeStamp = Date.now();
      createGameObject.gameTiming = gameTiming;
      createGameObject.id = gameId;
      createGameObject.gameName = gameName;
      const currentGame = this.state.currentGame;
      if(currentGame.gameName){
        const emailsSentCount = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then( function (snapshot) {return snapshot})
        let userAnswerCount = this.state.userAnswerCount;
        currentGame['users'] = this.state.userGameHistory;
        currentGame['answers'] = userAnswerCount;
        currentGame['prizesWon'] = emailsSentCount.numChildren();
        currentGame['tier_messages'] = this.state.tierMessaging || "";
      }
      const vm = this;
      const sortPlayersForAdmin = await this.sortPlayersForAdmin();
      base.post(`userAnswersHistory/${currentGame.id}`, {
        data: sortPlayersForAdmin[0],
        then(data){
          console.log("User Answers Saved!")
        }
      })
      base.push('gamesList', {
        data: currentGame,
        then(err){
          if(!err){
            vm.setState({
              currentGame: createGameObject,
              modal: false,
              currentUserAnswersState: [],
              tierMessaging: messaging_array
            }, ()=>{
              for(var i = 0; i < questionsAdded.length + 1; i++){
                vm.setState({
                  ['ticket_select' + i.toString()]: null,
                  ['rewardAmount' + i.toString()]: null,
                  ['codes' + i.toString()]: null,
                  ['codesArray' + i.toString()]: null,
                  ['linksArray' + i.toString()]: null,
                  ['pinsArray' + i.toString()]: null,
                  ['ticket_select_all_else' + i.toString()]: null,
                  ['winningWinnerHeader' + i.toString()]: null,
                  ['winningWinnerMessage' + i.toString()]: null,
                  ['winningMissedHeader' + i.toString()]: null,
                  ['winningMissedMessage' + i.toString()]: null,
                  ['rewardsAdded' + i.toString()]: null
                })
              }
              vm.setState({
                questionsAdded:[],
              })

              appDatabasePrimaryFunctions.ref("userAnswers").set(null);
              appDatabasePrimaryFunctions.ref("emailsSent").set(null);
              appDatabasePrimaryFunctions.ref("prizeWon").set(null);
            })
            vm.setGameToWatch(gameId);
          }
        }
      })
    }

    onDrop(files, rejected, myArgument) {
    if(rejected.length > 0){
      swal({
        title: 'Image cannot be uploaded',
        text: 'Make sure the image is less than 2mbs and it is an accepted file type',
        type: 'warning',
        confirmButtonText: 'Ok'
      })
      return;
    }
    const nameToUpdate = myArgument + "Preview";
    const fileToUpdate = files[0];
    this.setState({
      [nameToUpdate]: ({
        fileToUpdate,
        preview: URL.createObjectURL(fileToUpdate)
      })
    })

  }

    handleVideoChange(evt){
      const name_of_file = evt.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
        vm.setState({loading:false})
        if(res && res.error){
          swal({
            title: 'Video cannot be uploaded',
            text: res.error,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        } else if(res && res.imageUrl) {
          vm.setState({
            fileUploaded: true,
            videoLink: res.imageUrl
          });
        } else {
          swal({
            title: 'Video cannot be uploaded',
            text: "Something went wrong, please re-upload your video and try again!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        }
      })
    }

    detectHowManyCodesEntered(codesArray, areLinks){
      if(!codesArray){
        return [];
      }
      codesArray = codesArray.trim();
      const splitOnLineBreaks = codesArray.split("\n");
      let splitOnWhiteSpace = codesArray.split(/\s+/);
      let splitOnCommas = codesArray.split(",");
      let splitArray = splitOnLineBreaks;
      if(splitOnWhiteSpace.length === splitOnCommas.length){
        splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
        splitOnCommas = codesArray.replace(/\s/g,'').split(",");
      }
      if(splitArray.length < splitOnWhiteSpace.length){
        splitArray = splitOnWhiteSpace
      }
      if(splitArray.length < splitOnCommas.length){
        splitArray = splitOnCommas
      }
      if(areLinks){
        for(const linkIndex in splitArray){
          const link = splitArray[linkIndex];
          if(!validUrl(link)){
            return {message:"Invalid Url", inValidUrl: link};
          }
        }
      }
      return splitArray
    }

    stopGame(){
      var currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
      var currentGame = this.state.currentGame;
      currentGame.timeStamp = Date.now()
      currentGame.active = true;
      this.setState({
        currentGame: currentGame
      })
      swal({
          title: 'Game Started',
          type: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: true,
          timer: 10000
        })
    }

    endGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will start displaying the answers!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO DISPLAY ANSWERS',
        }).then((result)=>{
          if(result.value){
            this.setState({
              timeModal: true,
            })
            this.startRotation();
            swal({
              title: 'Answer Showing Started',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    startRotation(){
      this.setTimeouts()
    }

    cancelCountDown(){
      if(this.state.questionTimeOutId){
        clearTimeout(this.state.questionTimeOutId);
      }
      if(this.state.AnswersTimeOutId){
        clearTimeout(this.state.AnswersTimeOutId);
      }
      if(this.state.AnswerTimeOutId){
        clearTimeout(this.state.AnswerTimeOutId);
      }
      if(this.state.questionCountDownId){
        clearInterval(this.state.questionCountDownId);
      }
      if(this.state.answersCountDownId){
        clearInterval(this.state.answersCountDownId);
      }
      if(this.state.answerCountDownId){
        clearInterval(this.state.answerCountDownId);
      }
      const currentGame = this.state.currentGame;
      currentGame.screenToShow = 0;
      currentGame.questionToShow = 0;
      currentGame.rotationEnd = false;
      currentGame.rotate = false;
      for(const questionIndex in currentGame.questions){
        const question = currentGame.questions[questionIndex];
        question.shown = false;
      }

      this.setState({
        currentGame: currentGame,
        question: 0,
        timeModal: false,
      })
    }

    setTimeouts(){
      var tenantVariables = this.state.tenantVariables;
      var currentGame = this.state.currentGame;
      var secondsBetweenQuestions = tenantVariables.questionDisplayLength || "5";
      var secondsBetweenQuestionsConverted = parseInt(secondsBetweenQuestions + "000", 10)
      secondsBetweenQuestions = parseInt(secondsBetweenQuestions, 10)
      var questionNumber = this.state.question;
      var questionOn = (parseInt(questionNumber) + 1).toString();
      currentGame.screenToShow = 0
      currentGame.rotate = true
      currentGame.questionToShow = this.state.question
      this.setState({
        currentGame: currentGame,
        timeLeft: secondsBetweenQuestions,
        timerStatus: "Showing Question " + questionOn,
      })
      var vm = this;
      var questionCountDown = setInterval(function(){
        secondsBetweenQuestions = secondsBetweenQuestions - 1
        vm.setState({
          timeLeft: secondsBetweenQuestions,
          timerStatus: "Showing Question " + questionOn
        })
      }, 1000)
      var waitForQuestionTimeout = setTimeout(function (){
        clearInterval(questionCountDown);
        vm.setAnswerTimer()
      }, secondsBetweenQuestionsConverted)
      this.setState({
        questionTimeOutId: waitForQuestionTimeout,
        questionCountDownId: questionCountDown
      })
    }

    setAnswerTimer(){
      var tenantVariables = this.state.tenantVariables;
      var currentGame = this.state.currentGame
      var secondsShowingAnswer = tenantVariables.answerDisplayLength || "5";
      var secondsShowingAnswerConverted = parseInt(secondsShowingAnswer + "000", 10)
      secondsShowingAnswer = parseInt(secondsShowingAnswer, 10)
      currentGame.screenToShow = 2
      currentGame.questions[this.state.question].shown = true
      this.setState({
        timeLeft: secondsShowingAnswer,
        timerStatus: "Showing Correct Answer",
        currentGame: currentGame
      })
      var vm = this;
      var answerCountDown = setInterval(function(){
        secondsShowingAnswer = secondsShowingAnswer - 1
        vm.setState({
          timeLeft: secondsShowingAnswer
        })
      }, 1000)
      var waitForAnswerTimeout = setTimeout(function (){
        clearInterval(answerCountDown);
        if(vm.state.question < vm.state.currentGame.questions.length - 1){
          var question = parseInt(vm.state.question, 10) + 1
          vm.setState({
            question: question
          }, () => {
            vm.setTimeouts()
          })
        } else {
          currentGame.rotationEnd = true;
          currentGame.answered = true;
          vm.setState({
            timeLeft: 0,
            timeModal: false,
            currentGame: currentGame
          }, () => {
            vm.calculateWinners();
          })
        }
      }, secondsShowingAnswerConverted)
      this.setState({
        AnswerTimeOutId: waitForAnswerTimeout,
        answerCountDownId: answerCountDown
      })
    }

    async calculateWinners(){
      this.setState({
        loading: true
      })
      const currentGame = this.state.currentGame;
      const users = await appDatabasePrimaryFunctions.ref("users").once('value').then(function(snapshot){
        return snapshot.val()
      });
      const questions = currentGame.questions;
      const correctAnswers = [];
      for(let questionIndex in questions){
        let answers = questions[questionIndex].answers;
        for(let answerIndex in answers){
          if(answers[answerIndex].correct){
            correctAnswers.push(answers[answerIndex].id)
          }
        }
      }
      const userAnswers = await appDatabasePrimaryFunctions.ref("userAnswers").once('value').then(function(snapshot){
        return snapshot.val()
      });
      const usersAnswersAttempted = []
      for(let userAnswerIndex in userAnswers){
        let user = {}
        const answers = userAnswers[userAnswerIndex];
        const timeStamp = userAnswers[userAnswerIndex].timeStamp;
        let correctAnswersCount = 0;
        for(let answerIndex in answers){
          if(correctAnswers.indexOf(answers[answerIndex]) > -1){
            correctAnswersCount++;
          }
        }
        user['timeStamp'] = timeStamp;
        user['correctAnswers'] = correctAnswersCount;
        if(users[userAnswerIndex]){
          user['email'] = users[userAnswerIndex].email;
          user['name'] = users[userAnswerIndex].name;
        } else {
          continue;
        }
        usersAnswersAttempted.push(user)
      }

      const sortedRewards = currentGame.rewards || [];
      // let cmp = (a, b) => (a > b) - (a < b);
      // //orderby correct answers
      // usersAnswersAttempted.sort(function(a, b) {
      //   return cmp(b.correctAnswers,a.correctAnswers) || cmp(a.timestamp,b.timestamp)
      // });
      // //order reward levels by amount right to earn
      // sortedRewards.sort(function(a, b) {
      //   return cmp(b.answerLevel,a.answerLevel)
      // });
      this.sendRewardsToPlayers(sortedRewards, usersAnswersAttempted)
    }

    sendRewardsToPlayers(rewardsLevels, currentGameuserAnswersArray){
        if(currentGameuserAnswersArray.length === 0){
          swal({
            title: 'No Winners!',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({
            loading: false
          })
          return;
        }
        const adminRewardsData = {};
        adminRewardsData['rewardsLevels'] = rewardsLevels;
        adminRewardsData['currentGameUserAnswers'] = currentGameuserAnswersArray;
        adminRewardsData['tenantVariables'] = this.state.tenantVariables;
        const vm = this;
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/sendRewardsToFans`, {
        // fetch(`http://localhost:5001/trivia-dev-faa4f/us-central1/sendRewardsToFans`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(adminRewardsData)
        }).then(res => {
          if(res){
            Promise.resolve(res.json()).then(async function (value) {
              if(value.result === "finished"){
                const currentUserAnswersState = await vm.sortPlayersForAdmin(vm.state.itemsToShow);
                vm.setState({
                  currentUserAnswersState: currentUserAnswersState[0],
                  showLinkColumn: currentUserAnswersState[1],
                  loading:false
                })
                swal({
                  title: 'Emails Sent!',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
              } else {
                swal({
                  title: 'Something went wrong!  Check your internet connection and try again!',
                  type: 'error',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
                vm.setState({
                  loading: false
                })
              }
            })
          } else {
            swal({
              title: 'Something went wrong!  Check your internet connection and try again!',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
            this.setState({
              loading: false
            })
          }
        }).catch(err => {
          swal({
            title: 'Something went wrong!  Check your internet connection and try again!',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({
            loading: false
          })
        })
    }

    handleChange (evt) {
      var target = evt.target;
      var value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      const questionsAdded = this.state.questionsAdded || [];
      const questionsAddedLength = questionsAdded.length;
      let answersAddedState = {
        modal: !this.state.modal,
        wizardLocation: "first",
        question: 0,
      }
      for(let i = 0; i <questionsAddedLength;i++){
        answersAddedState['rewardsAdded'+i.toString()] = [];
        answersAddedState['ticket_select' + i.toString()] = null;
        answersAddedState['rewardAmount' + i.toString()] = null;
        answersAddedState['codes' + i.toString()] = null;
        answersAddedState['codesArray' + i.toString()] = null;
        answersAddedState['linksArray' + i.toString()] = null;
        answersAddedState['pinsArray' + i.toString()] = null;
        answersAddedState['ticket_select_all_else' + i.toString()] = null;
        answersAddedState['winningWinnerHeader' + i.toString()] = null;
        answersAddedState['winningWinnerMessage' + i.toString()] = null;
        answersAddedState['winningMissedHeader' + i.toString()] = null;
        answersAddedState['winningMissedMessage' + i.toString()] = null;
        answersAddedState['losingHeader' + i.toString()] = null;
        answersAddedState['losingMessage' + i.toString()] = null;
        answersAddedState['rewardsAdded' + i.toString()] = null
      }
      this.setState(answersAddedState);
    }

    timeModalToggle(){
      this.setState({
        timeModal: !this.state.timeModal
      })
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
            let currentGameState = await appDatabasePrimaryFunctions.ref('currentGame').once('value',function(snapshot){});
            currentGameState = currentGameState.val();
            currentGameState.active = false;
            currentGameState.answered = false;
            currentGameState.rotationEnd = false;
            currentGameState.startRotation = false;
            currentGameState.screenToShow = 0;
            currentGameState.rotate = false;
            for(let rewardLevelIndex in currentGameState.rewards) {
              const rewardLevel = currentGameState.rewards[rewardLevelIndex];
              rewardLevel.totalRewardsUsed = 0;
              for(let rewardLevelObjectParameter in rewardLevel){
                const parameter = rewardLevel[rewardLevelObjectParameter];
                if(typeof parameter === "object"){
                  parameter.used = 0
                }
              }
            }

            let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value', function(snapshot){});
            history = history.val();
            for(const questionIndex in currentGameState.questions){
               currentGameState.questions[questionIndex].shown = false
            }
            for(let historyRef in history){
              let refInstance = history[historyRef];
              if(refInstance && refInstance[currentGameState.id]){
                appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
              }
            }
            if(currentGameState.id){
              appDatabasePrimaryFunctions.ref(`currentGameFormFilled/${currentGameState.id}`).set(null);
            }
            appDatabasePrimaryFunctions.ref("emailsSent").set(null);
            appDatabasePrimaryFunctions.ref("userAnswers").set(null);
            appDatabasePrimaryFunctions.ref("prizeWon").set(null);

            this.cancelCountDown();
            this.setState({
              currentGame: currentGameState,
              question: 0,
              currentUserAnswersState: []
            })
          }
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'fourth') {
        this.setState({
          wizardLocation: 'third'
        })
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'fourth',
        })
        this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(otherTab3).classList.remove('active');
      document.getElementById(otherTab3).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
      document.getElementById(otherTab3+'1').classList.remove('active');
      document.getElementById(otherTab3+'1').classList.remove('show');
    }

    async switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
        })
      }else{
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
          loading: true
        })
        const currentUserAnswersState = await this.sortPlayersForAdmin();
        this.setState({
          showStats:true,
          loading: false,
          currentUserAnswersState: currentUserAnswersState[0],
          showLinkColumn: currentUserAnswersState[1]
        })
      }
    }

    detectUseOfIndex(text, index){
      text = text.replace(/%index%/g, index)
      return text
    }

    removeFromToQuestionAddArray(index){
      var answerArray = this.state.questionsAdded;
      answerArray.splice(index, 1)
      this.setState({
        questionsAdded: answerArray
      })
    }

    addQuestionToLocalArray(){
      let selectedQuestion = null;
      const questionsAddedArray = this.state.questionsAdded;
      const questionList = this.state.questionsList;
      for(const questionAddedIndex in questionsAddedArray){
        if(questionsAddedArray[questionAddedIndex].key === this.state.question_select){
          swal({
              title: "Question Already In Game!",
              text: "This game already includes this question.  Try adding another question.",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
      }
      for(const questionListIndex in this.state.questionsList){
        if(questionList[questionListIndex].key === this.state.question_select){
          selectedQuestion = questionList[questionListIndex];
          selectedQuestion['id'] = questionList[questionListIndex].key;
        }
      }
      if(selectedQuestion){
         questionsAddedArray.push(selectedQuestion);
         this.setState({
           questionsAdded: questionsAddedArray
         })
      }
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
      let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
      if(orderBy){
        baseRef = baseRef.orderByChild(orderBy)
      }
      if(numberOfResults){
        baseRef = baseRef.limitToFirst(numberOfResults);
      }
      return baseRef.once('value').then(function(snapshot){
        return snapshot;
      })
    }

    async sortPlayersForAdmin(numberOfResults=0, searchData=null){
      return await Promise.all([this.getSnapshotFromEndpoint('currentGame'), this.getSnapshotFromEndpoint('userAnswers', numberOfResults, searchData, 'timeStamp'), this.getSnapshotFromEndpoint('emailsSent'), this.getSnapshotFromEndpoint('users')]).then(async function(snapshots){
        let currentGame = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          currentGame = snapshots[0].val();
        }
        let userAnswers = {};
        if(snapshots[1].exists() && snapshots[1].val()){
          userAnswers = snapshots[1].val();
        }
        let emailsSent = {};
        if(snapshots[2].exists() && snapshots[2].val()){
          emailsSent = snapshots[2].val();
        }
        let users = {};
        if(snapshots[3].exists() && snapshots[3].val()){
          users = snapshots[3].val();
        }
        const questions = currentGame.questions;
        const correctAnswers = [];
        for(let questionIndex in questions){
          let answers = questions[questionIndex].answers;
          for(let answerIndex in answers){
            if(answers[answerIndex].correct){
              correctAnswers.push(answers[answerIndex].id)
            }
          }
        }
        const usersAnswersAttempted = [];
        let showLinkColumn = false;
        for(let userAnswerIndex in userAnswers){
          const user = {}
          const answers = userAnswers[userAnswerIndex];
          const userEmail = users[userAnswerIndex].email;
          let encodedRealEmail = btoa(userEmail);
          let rewardSent = emailsSent[encodedRealEmail] || emailsSent[userAnswerIndex] || false;
          let code = "";
          let link = "";
          if(rewardSent){
            code = rewardSent.code || "";
            link = rewardSent.link || "";
            if(link){
              showLinkColumn = true;
            }
            rewardSent = rewardSent.name;
          }
          const timeStamp = userAnswers[userAnswerIndex].timeStamp;
          let correctAnswersCount = 0;
          for(const answerIndex in answers){
            if(correctAnswers.indexOf(answers[answerIndex]) > -1){
              correctAnswersCount++;
            }
          }
          user['timeStamp'] = timeStamp;
          user['email'] = userEmail;
          user['uid'] = userAnswerIndex;
          user['correctAnswers'] = correctAnswersCount;
          user['rewardSent'] = rewardSent;
          user['code'] = code;
          user['link'] = link;
          if(searchData){
            const cleanSearchData = searchData.toLowerCase();
            const lowerCaseEmail = userEmail.toLowerCase();
            const lowerCaseUserAnswer = correctAnswersCount.toString();
            let lowerCaseRewardName = "";
            if(rewardSent){
              lowerCaseRewardName = rewardSent.toLowerCase();
            }
            if(lowerCaseEmail.indexOf(cleanSearchData) !== -1 || (lowerCaseUserAnswer && lowerCaseUserAnswer.indexOf(cleanSearchData) !== -1)  || lowerCaseRewardName.indexOf(cleanSearchData) !== -1){
              usersAnswersAttempted.push(user)
            }
          } else {
            usersAnswersAttempted.push(user)
          }
        }
        const cmp = (a, b) => (a > b) - (a < b);
        usersAnswersAttempted.sort(function(a, b) {
          return cmp(b.correctAnswers,a.correctAnswers) || cmp(a.timeStamp,b.timeStamp)
        });
        return [usersAnswersAttempted, showLinkColumn]
      })
    }

    addRewardToLocalArray(index){
      const rewardAmount = this.state['rewardAmount' + index.toString()];
      let allElsePrize = this.state['allElsePrize' + index.toString()];
      if(index === 0){
        allElsePrize = true
      }
      const rewardId = this.state['ticket_select'+index.toString()];
      const codes = this.state['codes' + index.toString()];
      const links = this.state['links' + index.toString()];
      let codesArray = this.state['codesArray' + index.toString()] || '';
      let linksArray = this.state['linksArray' + index.toString()] || '';
      let pinsArray = this.state['pinsArray' + index.toString()] || '';
      codesArray = codesArray.replace(/\n/g, " ");
      linksArray = linksArray.replace(/\n/g, " ");
      pinsArray = pinsArray.replace(/\n/g, " ");
      let totalCodes = [];
      let totalLinks = [];
      let totalPins = [];
      if(codesArray.trim() === "" && codes && !allElsePrize){
        swal({
          title: 'Hold on!',
          text: 'Must enter codes or uncheck codes box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (codes && !allElsePrize){
        totalCodes = this.detectHowManyCodesEntered(codesArray);
        totalPins = this.detectHowManyCodesEntered(pinsArray);
        if(totalCodes.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of codes must equal number of rewards created. In this case: " +  totalCodes.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        } else if(totalPins.length !== 0 && totalPins.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Pins not equal!',
            text: "# of pins must equal number of rewards created. In this case: " +  totalPins.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(linksArray.trim() === "" && links && !allElsePrize){
        swal({
          title: 'Hold on!',
          text: 'Must enter links or uncheck links box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (links && !allElsePrize){
        totalLinks = this.detectHowManyCodesEntered(linksArray, true);
        if(totalLinks.inValidUrl){
          swal({
            title: 'Invalid URL Detected!',
            text: "Links must be valid urls. In this case: " +  totalLinks.inValidUrl + " is not valid",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
        if(totalLinks.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of links must equal number of rewards created. In this case: " +  totalLinks.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(!rewardId){
        swal({
          title: 'Missing field!',
          text: "Missing reward.  Can't add a reward to a game without selecting a reward",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }

      if(!allElsePrize && (!rewardAmount || rewardAmount < 1 || rewardId.length < 1)){
        swal({
          title: 'Missing Amount',
          text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      const rewardToAddObject = {};
      for(let ticket in this.state.ticketList){
        if(this.state.ticketList[ticket].key === rewardId){
          rewardToAddObject.reward = this.state.ticketList[ticket];
          rewardToAddObject.allElsePrize = allElsePrize;
          rewardToAddObject.amount = allElsePrize ? null : rewardAmount;
          rewardToAddObject.codes = allElsePrize ? false : codes;
          rewardToAddObject.links = allElsePrize ? false : links;
          rewardToAddObject.codesArray = allElsePrize ? null : totalCodes;
          rewardToAddObject.linksArray = allElsePrize ? null : totalLinks;
          rewardToAddObject.pinsArray = allElsePrize ? null : totalPins;
        }
      }
      let array = this.state['rewardsAdded'+index.toString()] || [];
      let rewardsAddedIds = [];
      for(let prize in array){
        if(array[prize].allElsePrize && rewardToAddObject.allElsePrize){
          swal({
            title: 'Hold On!',
            text: "Cannot add two All Else Prizes to the same tier",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        }
        rewardsAddedIds.push(array[prize].reward.key)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          ['rewardsAdded'+index.toString()]: array,
          convertToQrCode: false,
          codes: false,
          links: false
        })
      } else {
        swal({
          title: 'Prize already added!',
          text: "You have already added this prize",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
    }

    removeFromToAddArray(index,tier){
      let rewardArray = this.state['rewardsAdded'+tier.toString()];
      rewardArray.splice(index, 1)
      this.setState({
        ['rewardsAdded'+tier.toString()]: rewardArray
      })
    }

    async searchFirebaseData(searchData){
      this.setState({
        loading: true
      })
      let numberOfResults = this.state.itemsPerPage;
      if(searchData){
        numberOfResults = null;
      }
      const organizeUserAnswersArray = await this.sortPlayersForAdmin(numberOfResults, searchData);
      this.setState({
        loading: false,
        currentUserAnswersState: organizeUserAnswersArray[0],
        showLinkColumn: organizeUserAnswersArray[1]
      })
    }

    render() {
      let selectedGame = this.state.currentGame || null;
      let selectedGameQuestions = [];
      let selectedGameRewards = [];
      const tenantVariables = this.state.tenantVariables;
      let players = this.state.userGameHistory || 0;
      let userAnswersCount = this.state.userAnswerCount || 0;
      let gameActive = false;
      let currentUserAnswersState = this.state.currentUserAnswersState || [];
      let hideEndGameButton = false;
      let questionsAddedLength = [];
      for (let i = -1; i < this.state.questionsAdded.length; i++) {
        questionsAddedLength.push(i);
      }
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      let showAdvancedButton = false;
      let gameScheduledStatus = "completed";
      if(selectedGame && selectedGame.id){
        selectedGameQuestions = selectedGame.questions || [];
        selectedGameRewards = selectedGame.rewards || [];
        gameActive = selectedGame.active;

        if(selectedGame.answered || selectedGame.gameTiming === "immediate" || this.state.emailsSent || (selectedGame.rotationEnd && selectedGameRewards.length === 0) || (selectedGame.gameTiming === "scheduled") && !this.state.advanceSettings){
          hideEndGameButton = true
        }
        if(selectedGame.gameTiming === "scheduled" && !this.state.emailsSent && !(selectedGame.rotationEnd && selectedGameRewards.length === 0)){
          showAdvancedButton = true
        }
        if(selectedGame.answered || (this.state.emailsSent && selectedGame.gameTiming === "scheduled") || selectedGame.rotationEnd){
          selectedGame.answered = true
        }
        if(selectedGame.scheduleInfo){
          startTimeFirstPart = new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleDateString();
          startTimeSecondPart = new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleTimeString();
          endTimeFirstPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleDateString();
          endTimeSecondPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleTimeString();
          gameScheduledStatus = selectedGame.scheduleInfo.status
        }
      } else if(selectedGame && !selectedGame.id){
        selectedGame = null;
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2" style={{display: selectedGame && selectedGame.answered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: selectedGame && !selectedGame.answered ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: selectedGame && !selectedGame.answered ? '' : 'none'}}>
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <center className="col-md-1" style={{display: selectedGame && showAdvancedButton && !selectedGame.answered ? '' : 'none', color:'black'}}>
                  Advanced<input id="advanceSettings" name="advanceSettings" type="checkbox" checked={this.state.advanceSettings} onChange={this.handleChange} />
                </center>
                <div className="col-md-2">
                  <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{fontSize: "0.8rem", display: selectedGame && !hideEndGameButton ? '' : 'none', float:'left', height:'52px' }}>Start Scoreboard</button>
                </div>
                <div className="col-md-5">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame && !selectedGame.key ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <center style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="container">
                <div className="row">
                  {startTimeFirstPart &&
                    <div className="col-md-4"
                         style={{color: "black", border: "solid 1px #d3d3d3", margin: 10, borderRadius: 5}}>
                      <div className="row">
                        <div className="col-md-6">
                          <u style={{color: "#353839"}}>Starts</u>
                          <br/>
                          {selectedGame && selectedGame.scheduleInfo && selectedGame.scheduleInfo.performAt  ?
                              <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                              :
                              <span style={{fontSize: 18}}>No Scheduled Start</span>
                          }
                          {/*<span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>*/}
                        </div>
                        <div className="col-md-6">
                          <u style={{color: "#353839"}}>Ends</u>
                          <br/>
                          {selectedGame && selectedGame.scheduleInfo && selectedGame.scheduleInfo.endAt  ?
                              <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                          :
                              <span style={{fontSize: 18}}>No Scheduled End</span>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, marginLeft:10, borderRadius: 5}}>
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>Current Game Players</span>
                        <footer className="value-text">{players}</footer>
                      </div>
                      <div className="col-md-6" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>Answers</span>
                        <footer className="value-text">{userAnswersCount}</footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </center>
            <div className="" style={{display:selectedGame?'':'none'}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="row" style={{marginLeft: 10, marginRight: 10}}>
                    <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                      <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                    <div className="form-group" style={{marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto'}}>
                      <input id="searchQuery" name="searchQuery" className="form-control" placeholder="search" type="text" onChange={()=>{
                        const vm = this;
                        clearTimeout(this.timeoutId); // no-op if invalid id
                        this.timeoutId = setTimeout(function(){
                          let input = document.getElementById('searchQuery').value;
                          vm.searchFirebaseData(input);
                        }, 500);
                      }}/>
                    </div>
                  </div>
                  <div style={{height: '10px', width: '100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>Number Correct</th>
                        <th>Time Stamp</th>
                        <th>Place</th>
                        <th>Reward Sent</th>
                        <th>Code</th>
                        {this.state.showLinkColumn &&
                          <th>Link</th>
                        }
                      </tr>
                      {
                        currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.correctAnswers}</td>
                              <td style={{fontFamily:'Open Sans' }}>{convertTimeStampToHumanReadable(item.timeStamp)}</td>
                              <td style={{fontFamily:'Open Sans' }}>{i + 1}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                              {this.state.showLinkColumn &&
                                <td style={{fontFamily:'Open Sans' }}>{item.link || "NONE"}</td>
                              }
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Prizes</h4>
                        {selectedGameRewards && selectedGameRewards.length > 0 && valueDoesExist(selectedGameRewards[0].answerLevel) ?
                            <Accordion style={{margin:10}}>
                              {
                                selectedGameRewards.sort(function(a,b){return a.answerLevel - b.answerLevel;}).map(function (item, i) {
                                  const rewards = [];
                                  let allElseReward;
                                  for(const rewardObjetIndex in item){
                                    const reward = item[rewardObjetIndex];
                                    if(typeof reward === "object"){
                                      if(reward.allElsePrize){
                                        allElseReward = reward;
                                      } else {
                                        rewards.push(reward);
                                      }
                                    }
                                  }
                                  return <AccordionItem className="blackBackgroundColor" bodyClassName="changeBackgroundColor" key={i} title={`${(item.answerLevel).toString()} correct`} expanded={i === 0}>
                                      {
                                        rewards.map(function(reward, index){
                                          return <div style={{color:"black"}}>
                                            <span>{reward.amount}, {reward.rewardName}</span>
                                          </div>
                                        })
                                      }
                                      {allElseReward &&
                                      <div style={{color:"black"}}>
                                        {rewards.length > 0 &&
                                          <br/>
                                        }
                                        <span><span style={{color:"grey", fontWeight: 100, fontFamily: "sans-serif"}}>Everyone Else Wins</span> {allElseReward.rewardName}</span>
                                      </div>
                                      }
                                  </AccordionItem>
                                })
                              }
                            </Accordion>
                            :
                            <>
                              {selectedGameRewards && selectedGameRewards.length > 0 ?
                                    <>
                                      {selectedGameRewards.map(function(reward, index){
                                        return <div style={{color:'black'}}>
                                          {reward.rewardName}
                                        </div>
                                      })}
                                    </>
                                  :
                                  <span style={{color:'black'}}>
                                    No Prizes Added
                                  </span>
                              }
                            </>
                        }
                      </div>
                      <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Questions</h4>
                        <Carousel style={{backgroundColor:'white'}} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem} onChange={(e) => {
                          this.setState({
                            selectedItem: e,
                          });
                        }}>
                          {
                            selectedGameQuestions.map(function(item, i){
                              return <div style={{backgroundColor: "#FAFBFE", padding:10}} key={i}>
                                <p style={{color:'black'}}>{i+1}/{selectedGameQuestions.length.toString()}</p>
                                <p style={{color:'black'}}>{item.questionText}</p>
                                <ol style={{textAlign:'left'}}>
                                  {
                                    item.answers.map(function(answer,i) {
                                      if(answer.correct){
                                        return <li key={i} style={{color:'green'}}>{answer.answerText}</li>
                                      } else {
                                        return <li key={i} style={{color:'black'}}>{answer.answerText}</li>
                                      }
                                    })
                                  }
                                </ol>
                              </div>
                            })
                          }
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-question"/>
                                <span className="d-none d-sm-inline"> Add Questions</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('fourth', 'first', 'second', 'third')}>
                          <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fourth1">
                            <span className="fa fa-sign-out"/>
                            <span className="d-none d-sm-inline"> Finish</span>
                          </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group row mb-3">
                                      <label className="col-md-12 col-form-label" htmlFor="name3"> Answer Timing</label>
                                      <div className="col-md-9" onChange={this.handleChange}>
                                        <input type="radio" value="manual" name="gameTiming" checked={this.state.gameTiming === "manual"}/> <span style={{marginRight:10, fontSize:10}}>Manual</span>
                                        <input type="radio" value="scheduled" name="gameTiming" checked={this.state.gameTiming === "scheduled"}/> <span style={{marginRight:10, fontSize:10}}>Scheduled</span>
                                        <input type="radio" value="immediate" name="gameTiming" checked={this.state.gameTiming === "immediate"}/> <span style={{fontSize:10}}>Immediate</span>
                                        <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "manual" ? 'block' : 'none'}}>"Manual" requires a button click to start showing answers. Best for stadiums/live events or when the time to show answers is unclear.</p>
                                        <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "scheduled" ? 'block' : 'none'}}>"Scheduled" requires a start time and answer time and will show answers at the answer time and then distribute prizes</p>
                                        <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "immediate" ? 'block' : 'none'}}>"Immediate" shows the answers as soon as the user has finished answering all of the questions. The prizes will then be sent if appropriate</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.gameTiming !== "scheduled" &&
                                  <div className="form-group">
                                    <label htmlFor="showScheduleTime">Set Schedule Time</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.showScheduleTime} id="showScheduleTime" name="showScheduleTime" onChange={this.handleChange}/>
                                  </div>
                                }
                                {(this.state.showScheduleTime || this.state.gameTiming === "scheduled") &&
                                  <>
                                    <div className="form-group row mb-3">
                                      <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                      <div className="col-md-9">
                                        <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-3" style={{display: this.state.gameTiming !== "manual" ? 'flex' : 'none'}}>
                                      <label className="col-md-3 col-form-label" htmlFor="endTime"> {this.state.gameTiming === "scheduled"?"Set Answer Time":"Set Stop Time"}</label>
                                      <div className="col-md-9">
                                        <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                      </div>
                                    </div>
                                  </>
                                }
                                <div className="form-group">
                                  <label htmlFor="showAdvancedSettings">Advanced</label>
                                  <br/>
                                  <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                {this.state.advanced &&
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-12 col-form-label" htmlFor="name3"> Add Ending Video</label>
                                        <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                          <div className="col-md-6">
                                            <label htmlFor="videoLink">Link</label>
                                            <input type="url" id="videoLink" name="videoLink" className="form-control" value={this.state.videoLink} onChange={this.handleChange} placeholder="https://endvideolink.com"/>
                                          </div>
                                          <div className="col-md-1">
                                            <h2><b>OR</b></h2>
                                          </div>
                                          <div className="col-md-2">
                                            <input style={{display:'none'}}  id="raceVideoFile" name="raceVideoFile" type="file" ref={input => {this.raceVideoFile = input; }} onChange={this.handleVideoChange}/>
                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('raceVideoFile').click()} />
                                          </div>
                                        </div>
                                        <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.videoLink? '' : 'none'}}>
                                          Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({videoLink: null, fileUploaded:false}, () => {
                                          document.getElementById('videoLink').value="";
                                        })}> ❌</span>
                                          {this.state.add_video && this.state.videoLink && !this.state.videoLength &&
                                          <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                          }
                                          <ReactPlayer
                                              style={{display: this.state.videoLink? '' : 'none'}}
                                              url={this.state.videoLink}
                                              onDuration={(e)=> this.setState({videoLength: e})}
                                              onError={(e)=>this.setState({videoLength: null})}
                                              muted={true}
                                              playing={true}
                                              controls={true}
                                              preload={"auto"}
                                              width="100%"
                                              height="auto"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                            </form>
                        </div>

                        <div className="tab-pane" id="second">
                          <div className="form-group row mb-3">
                            <label className="col-md-3 col-form-label" htmlFor="name3"> Select Question</label>
                            <div className="col-md-9">
                              <select className="form-control" name="question_select" id="question_select" value={this.state.question_select} onChange={this.handleChange}>
                                <option/>
                                {
                                  this.state.questionsList.map(function(item,index){
                                    return(
                                        <option value={item.key} key={index}>{item.questionText}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                            <div className="form-group mb-3">
                              <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                {
                                  this.state.questionsAdded.map(function(item,index){
                                    return(
                                        <div key={index} className="form-group">
                                          <li>{item.questionText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToQuestionAddArray(index)}/></li>
                                        </div>
                                    )
                                  })
                                }
                              </ol>
                            </div>
                            <div className="form-group row mb-3" align="center">
                              <div className="col-md-12">
                                <button className="btn btn-primary btn-admin" onClick={()=>this.addQuestionToLocalArray()}>Add Question</button>
                              </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                          <div className="form-horizontal">
                            <div className="row">
                              <div className="col-12" style={{textAlign:'center'}}>
                                <div className="form-check">
                                  <input value={this.state.noPrizes} className="form-check-input" id="noPrizes" name="noPrizes" type="checkbox" checked={this.state.noPrizes} onChange={this.handleChange} />
                                  <label className="form-check-label" htmlFor="codes">No Prizes</label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <Accordion style={{margin:10}}>
                                  {
                                    questionsAddedLength.map(function(item,index){
                                      var winningHeader = "You win!"
                                      var winningMessage = "Check your email for your prize!"
                                      var winningMissedHeader = "Nice Job!"
                                      var winningMissedMessage = "You got " + index.toString() + " correct! Unfortunately you didn't win a prize but try again soon!"
                                      if(index > 0){
                                        winningMessage = "You got " + index.toString() + " correct! Check your email for your prize!"
                                      }
                                      if(this.state['winningWinnerHeader' + index.toString()] != undefined){
                                        winningHeader = this.state['winningWinnerHeader' + index.toString()]
                                      } else if(tenantVariables.globalNoneCorrectPrizeHeader && tenantVariables.globalNoneCorrectPrizeHeader.length > 0 && index === 0) {
                                        winningHeader = this.detectUseOfIndex(tenantVariables.globalNoneCorrectPrizeHeader, index)
                                      } else if (tenantVariables.globalPrizeWonHeader && tenantVariables.globalPrizeWonHeader.length > 0) {
                                        winningHeader = this.detectUseOfIndex(tenantVariables.globalPrizeWonHeader, index)
                                      }
                                      if(this.state['winningWinnerMessage' + index.toString()] != undefined){
                                        winningMessage = this.state['winningWinnerMessage' + index.toString()]
                                      } else if(tenantVariables.globalNoneCorrectPrizeBody && tenantVariables.globalNoneCorrectPrizeBody.length > 0 && index === 0) {
                                        winningMessage = this.detectUseOfIndex(tenantVariables.globalNoneCorrectPrizeBody, index)
                                      } else if (tenantVariables.globalPrizeWonBody && tenantVariables.globalPrizeWonBody.length > 0) {
                                        winningMessage = this.detectUseOfIndex(tenantVariables.globalPrizeWonBody, index)
                                      }
                                      if(this.state['winningMissedHeader' + index.toString()] != undefined){
                                        winningMissedHeader = this.state['winningMissedHeader' + index.toString()]
                                      } else if (tenantVariables.globalNoPrizeHeader && tenantVariables.globalNoPrizeHeader.length > 0) {
                                        winningMissedHeader = this.detectUseOfIndex(tenantVariables.globalNoPrizeHeader, index)
                                      }
                                      if(this.state['winningMissedMessage' + index.toString()] != undefined){
                                        winningMissedMessage = this.state['winningMissedMessage' + index.toString()]
                                      } else if (tenantVariables.globalNoPrizeBody && tenantVariables.globalNoPrizeBody.length > 0) {
                                        winningMissedMessage = this.detectUseOfIndex(tenantVariables.globalNoPrizeBody, index)
                                      }
                                      return(
                                          <AccordionItem key={index} className="if-number-correct-item" title={`If a fan answers ${(index).toString()} correct`} expanded={index === 0}>
                                            {!this.state.noPrizes &&
                                            <span>
                                                  <div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label" htmlFor={`ticket_select${(index).toString()}`}> Select Prize</label>
                                                    <div className="col-md-6">
                                                      <select className="form-control" name={`ticket_select${(index).toString()}`} id={`ticket_select${(index).toString()}`} value={this.state['ticket_select' + index.toString()]} onChange={this.handleChange}>
                                                        <option/>
                                                        {
                                                          this.state.ticketList.map(function (item, index) {
                                                            return (
                                                                <option value={item.key}
                                                                        key={index}>{item.rewardName}</option>
                                                            )
                                                          })
                                                        }
                                                      </select>
                                                    </div>
                                                    <div className="col-md-3" style={{alignSelf:"center", textAlign: "right"}}>
                                                      <div className="form-check">
                                                        <input id={`allElsePrize${(index).toString()}`} className="form-check-input" name={`allElsePrize${(index).toString()}`} type="checkbox" checked={this.state['allElsePrize' + index.toString()] || index === 0} onChange={this.handleChange} />
                                                        <label className="form-check-label" htmlFor={`allElsePrize${(index).toString()}`}>All Else Prize</label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: index > 0 && !this.state['allElsePrize' + index.toString()] ? 'flex' : 'none', alignItems:'center'}}>
                                                    <label className="col-md-3 col-form-label" htmlFor={`rewardAmount${(index).toString()}`}> How Many Of This Reward To Give Out?</label>
                                                    <div className="col-md-4">
                                                      <input id={`rewardAmount${(index).toString()}`} name={`rewardAmount${(index).toString()}`} type="number" className="form-control" value={this.state['rewardAmount' + index.toString()]} onChange={this.handleChange} placeholder="5" />
                                                    </div>
                                                    <div className="form-check">
                                                      <input id={`codes${(index).toString()}`} className="form-check-input" name={`codes${(index).toString()}`} type="checkbox" checked={this.state['codes' + index.toString()]} onChange={this.handleChange} />
                                                      <label className="form-check-label" htmlFor={`codes${(index).toString()}`}>Add Codes</label>
                                                    </div>
                                                    <div className="form-check" style={{marginLeft: 5}}>
                                                      <input id={`links${(index).toString()}`} className="form-check-input" name={`links${(index).toString()}`} type="checkbox" checked={this.state['links' + index.toString()]} onChange={this.handleChange} />
                                                      <label className="form-check-label" htmlFor={`links${(index).toString()}`}>Add Links</label>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['codes' + index.toString()] && !this.state['allElsePrize' + index.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`codesArray${(index).toString()}`} className="col-md-3 col-form-label">Enter Codes (code1,code2)</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['codesArray' + index.toString()]} className="form-control" name={`codesArray${(index).toString()}`} onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['codes' + index.toString()] && !this.state['allElsePrize' + index.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`pinsArray${(index).toString()}`} className="col-md-3 col-form-label">Enter Pins</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['pinsArray' + index.toString()]} className="form-control" name={`pinsArray${(index).toString()}`} onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['links' + index.toString()] && !this.state['allElsePrize' + index.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`linksArray${(index).toString()}`} className="col-md-3 col-form-label">Enter links</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['linksArray' + index.toString()]} className="form-control" name={`linksArray${(index).toString()}`} onChange={this.handleChange} placeholder="https://myfirstlink.com, https://mysecondlink.com, https://mythirdlink.com"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" align="center">
                                                      <div className="col-md-12">
                                                          <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray(index)}>Add Reward</button>
                                                      </div>
                                                  </div>
                                              {this.state['rewardsAdded'+index.toString()] && this.state['rewardsAdded'+index.toString()].length > 1 &&
                                              <div className="form-check" >
                                                <input value={this.state['randomOrder'+index.toString()]} className="form-check-input" id={`randomOrder${(index).toString()}`} name={`randomOrder${(index).toString()}`} type="checkbox" checked={this.state['randomOrder' +index.toString()]} onChange={this.handleChange} />
                                                <label className="form-check-label" htmlFor={`randomOrder${(index).toString()}`}>Give Out In Random Order</label>
                                              </div>
                                              }
                                              <div className="form-group mb-3">
                                                      <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                                          {
                                                            this.state['rewardsAdded'+index.toString()] && this.state['rewardsAdded'+index.toString()].map(function(item,addedIndex){
                                                              let codesText = "No"
                                                              if(item.codes){
                                                                codesText = "Yes"
                                                              }
                                                              return(
                                                                  <div key={addedIndex} className="form-group">
                                                                    <li>) {item.amount || "All Else Prize"} - {item.reward.rewardName}{item.amount && "; Codes: "+codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(addedIndex,index)}/></li>
                                                                  </div>
                                                              )
                                                            })
                                                          }
                                                      </ol>
                                                  </div>
                                                </span>
                                            }
                                            <div className="form-check">
                                              <input className="form-check-input" name={`textEditChanges${(index).toString()}`} type="checkbox" checked={this.state['textEditChanges' + index.toString()]} onChange={this.handleChange} />
                                              <label className="form-check-label" htmlFor={`textEditChanges${(index).toString()}`}>Advanced Text Changes</label>
                                            </div>
                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && !this.state.noPrizes ? 'flex' : 'none', alignItems: "center" }}>
                                              <label htmlFor={`winningWinnerHeader${(index).toString()}`} className="col-md-3 col-form-label">Prize Header</label>
                                              <div className="col-md-9">
                                                <input value={winningHeader} className="form-control" name={`winningWinnerHeader${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] && !this.state.noPrizes ? 'flex' : 'none', alignItems: "center" }}>
                                              <label htmlFor={`winningWinnerMessage${(index).toString()}`} className="col-md-3 col-form-label">Prize Message</label>
                                              <div className="col-md-9">
                                                <input value={winningMessage} className="form-control" name={`winningWinnerMessage${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] ? 'flex' : 'none', alignItems: "center" }}>
                                              <label htmlFor={`winningMissedHeader${(index).toString()}`} className="col-md-3 col-form-label">No Prize Header</label>
                                              <div className="col-md-9">
                                                <input value={winningMissedHeader} className="form-control" name={`winningMissedHeader${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + index.toString()] ? 'flex' : 'none', alignItems: "center" }}>
                                              <label htmlFor={`winningMissedMessage${(index).toString()}`} className="col-md-3 col-form-label">No Prize Message</label>
                                              <div className="col-md-9">
                                                <input value={winningMissedMessage} className="form-control" name={`winningMissedMessage${(index).toString()}`} type="text" onChange={this.handleChange}/>
                                              </div>
                                            </div>
                                          </AccordionItem>
                                      );
                                    }, this)
                                  }
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="fourth">
                          <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                          <div className="row">
                            <div className="col-12">
                              <div className="text-center">
                                <h2 className="mt-0">
                                  <i className="mdi mdi-check-all"/>
                                </h2>
                                <h3 className="mt-0">Finish</h3>

                                <div className="row form-group">
                                  <div className="col-md-12">
                                    <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                      <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'fourth' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'fourth' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal isOpen={this.state.timeModal} toggle={this.timeModalToggle} style={{width: '90%'}} id="myTimeModal">
              <div className="card">
                <center className="card-body">
                  <h4 className="header-title mb-3"> {this.state.timerStatus}</h4>
                  <span style={{fontSize: "30px"}}>{this.state.timeLeft}</span>
                  <div>
                    <p>DO NOT REFRESH OR QUIT THE BROWSER WHILE THE TIMER IS RUNNING</p>
                    <button className="btn btn-danger" onClick={() => this.cancelCountDown()}>CANCEL COUNTDOWN AND RESET</button>
                  </div>
                </center>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
