import axios from 'axios';

export default class UploadImage {
  async upload_file(file) {
    const formData = new FormData();
    formData.append('file', file);
    if(file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")){
        if( file.size > 2200000 ){
          return {error:"File too big: max size is 2 MB"};
        }
    } else if(file && file.type === "video/mp4") {
      if( file.size > 22000000 ) {
        return {error:"File too big: max size is 20 MB"};
      }
    } else {
      return {error: "Wrong file type"};
    }
    return await axios.post('https://nodeimageuploader.herokuapp.com/image_upload',formData, {
    // return await axios.post(`http://localhost:8000/image_upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(function(response){
      if(response && response.data && response.data.error){
        return {error: response.data.error.errorMessage}
      } else if(response && response.data){
        return {imageUrl: response.data}
      } else {
        return {}
      }
    })
    .catch(function (error){
      console.log(error)
      return {}
    })
  }
}

