import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import {isObjectEmpty} from "../utils/HelpfulFunction";
import swal from 'sweetalert2';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: "",
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            rulesAndRegsLink: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.rulesAndRegsLinkRef = base.bindToState(`tenantRules/rulesAndRegsLink`, {
            context: this,
            state: 'rulesAndRegsLink',
        });
        this.howToPlayLinkRef = base.bindToState(`tenantRules/howToPlayLink`, {
            context: this,
            state: 'howToPlayLink',
        });
        this.tenantRulesRef = base.listenTo(`tenantRules`, {
            context: this,
            then(data){
                if(data.howToPlayText && data.howToPlayText !== "<p><br></p>"){
                    this.setState({
                        howToPlayText: RichTextEditor.createValueFromString(data.howToPlayText, 'html'),
                        advanced: true
                    })
                } else if(data.howToPlayLink){
                    this.setState({
                        advanced: true
                    })
                }
                if(data.rulesPopUpText){
                    this.setState({
                        rulesPopUpText: RichTextEditor.createValueFromString(data.rulesPopUpText, 'html'),
                        advanced: true
                    })
                }
                if(data.rulesShowInAppPopUpText){
                    this.setState({
                        rulesShowInAppPopUpText: RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html')
                    })
                }
                if(data.rulesShowInApp){
                    this.setState({
                        rulesShowInApp: true,
                        advanced: true
                    })
                }
            }
        });
        this.rulesAndRegsTextRef = base.bindToState(`tenantRules/rulesAndRegsText`, {
            context: this,
            state: 'rulesAndRegsText',
        });
        this.rulesPopUpHeaderRef = base.bindToState(`tenantRules/rulesPopUpHeader`, {
            context: this,
            state: 'rulesPopUpHeader',
        });
        this.rulesInAppButtonTextRef = base.bindToState(`tenantRules/rulesInAppButtonText`, {
            context: this,
            state: 'rulesInAppButtonText',
        });
        this.rulesShowInAppPopUpHeaderRef = base.bindToState(`tenantRules/rulesShowInAppPopUpHeader`, {
            context: this,
            state: 'rulesShowInAppPopUpHeader',
        });
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.rulesAndRegsTextRef);
        base.removeBinding(this.rulesAndRegsLinkRef);
        base.removeBinding(this.howToPlayLinkRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.rulesPopUpHeaderRef);
        base.removeBinding(this.rulesShowInAppPopUpHeaderRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        let rulesAndRegsText = this.state.rulesAndRegsText;
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let rulesAndRegsLink = this.state.rulesAndRegsLink;
        let howToPlayLink = this.state.howToPlayLink;
        let rulesPopUpText = this.state.rulesPopUpText.toString('html');
        let howToPlayText = this.state.howToPlayText.toString('html');
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText.toString('html');
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        if(rulesInAppButtonText && rulesInAppButtonText.length > 18){
            swal({
                title: "Input Error",
                text: "Button Text Cannot Be Longer Then 18 Characters",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        let rulesShowInApp = this.state.rulesShowInApp || false;
        if(howToPlayText === "<p><br></p>"){
            howToPlayText = "";
        }
        if(rulesPopUpText === "<p><br></p>"){
            rulesPopUpText = "";
        }
        if(rulesShowInAppPopUpText === "<p><br></p>"){
            rulesShowInAppPopUpText = "";
        }
        let updateRulesObject = {
            "rulesAndRegsText": rulesAndRegsText, "rulesAndRegsLink": rulesAndRegsLink, "howToPlayLink": howToPlayLink,
            "howToPlayText": howToPlayText, "rulesPopUpText": rulesPopUpText, "rulesPopUpHeader":rulesPopUpHeader,
            "rulesShowInAppPopUpText": rulesShowInAppPopUpText, "rulesShowInAppPopUpHeader": rulesShowInAppPopUpHeader,
            "rulesInAppButtonText": rulesInAppButtonText, "rulesShowInApp": rulesShowInApp
        }
        this.setState({loading:true})
        const vm = this;
        base.post("tenantRules/", {
            data: updateRulesObject,
            then(err){
              vm.setState({loading:false})
              if(!err){
                swal({
                    title: 'Rules and Regs Updated!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                console.log(err)
              }
            }
        })
    }

    onChangeHowToPlay = (howToPlayText) => {
        this.setState({
            howToPlayText,
        })
    }

    onChangePopUpText = (rulesPopUpText) => {
        this.setState({
            rulesPopUpText
        })
    }

    onChangeRulesShowText = (rulesShowInAppPopUpText) => {
        this.setState({
            rulesShowInAppPopUpText
        })
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let rulesAndRegsText = this.state.rulesAndRegsText;
        let rulesAndRegsLink = this.state.rulesAndRegsLink;
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let rulesPopUpText = this.state.rulesPopUpText;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText;
        let howToPlayLink = this.state.howToPlayLink;
        let howToPlayText = this.state.howToPlayText;
        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(rulesAndRegsLink)){
            rulesAndRegsLink = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        const advanced = this.state.advanced || false;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-game-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <div className="form-group">
                          <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                          <input type="text" id="rulesAndRegsText" name="rulesAndRegsText" className="form-control" value={rulesAndRegsText} onChange={this.handleChange}/>
                      </div>
                      <div className="form-group">
                          <label htmlFor="rulesAndRegsLink">Rules And Regs Link</label>
                          <p className="text-muted2" style={{fontSize:'10px'}}>This is where your rules and regulations are hosted</p>
                          <input id="rulesAndRegsLink" name="rulesAndRegsLink" type="url" className="form-control" value={rulesAndRegsLink} onChange={this.handleChange} placeholder="https://ourrules.com"/>
                      </div>
                      <div className="form-group">
                          <label htmlFor="showAdvancedSettings">Advanced</label>
                          <br/>
                          <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                      </div>
                      {advanced &&
                      <>
                          <div className="form-group">
                              <label htmlFor="howToPlayLink">How To Play Link (Optional)</label>
                              <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                              <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                              <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                              <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={this.onChangeHowToPlay}/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                              <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up containing rules</p>
                              <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                              <p className="text-muted2" style={{fontSize:'10px'}}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                              <RichTextEditor id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={this.onChangePopUpText}/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                              <br/>
                              <input type="checkbox" checked={this.state.rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange}/>
                          </div>
                          {this.state.rulesShowInApp &&
                          <>
                              <div className="form-group">
                                  <label htmlFor="rulesInAppButtonText">Button Text</label>
                                  <p className="text-muted2" style={{fontSize:'10px'}}>The text of the button that links to Rules & Regs</p>
                                  <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                  <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up</p>
                                  <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                  <p className="text-muted2" style={{fontSize:'10px'}}>The body text of the pop up</p>
                                  <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={this.onChangeRulesShowText}/>
                              </div>
                          </>
                          }
                      </>
                      }
                  </form>
                </div>
              </div>
              <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
