import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleSponsorLogo from '../../styles/images/sponsor_logo.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import sampleScoreboardTopImage from '../../styles/images/top_scoreboard.png'
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOneMajor: true,
            showTabTwoMajor: false,
            showTabThreeMajor: false,
            showTabOneMinor: true,
            showTabTwoMinor: false,
            showTabThreeMinor: false,
            loading:true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            advanced: false,
            advancedTextChanges: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
          then(){
            const tenantVariables = this.state.tenantVariables;
            let advanced = false;
            let advancedTextChanges = false;
            if(tenantVariables && tenantVariables.formHeaderText){
                advanced = true;
            }
            if(tenantVariables && (tenantVariables.globalPrizeWonHeader || tenantVariables.globalPrizeWonBody || tenantVariables.globalNoPrizeHeader || tenantVariables.globalNoPrizeBody || tenantVariables.globalNoneCorrectPrizeHeader || tenantVariables.globalNoneCorrectPrizeBody)){
                advancedTextChanges = true;
            }
            if(tenantVariables){
                this.setState({
                    tenantVariables: tenantVariables,
                    advanced: advanced,
                    advancedTextChanges: advancedTextChanges
                })
            }
          }
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
      event.preventDefault();
      const variables = this.state.tenantVariables;
      const vm = this;
      base.post("tenantVariables/", {
        data: variables,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Tenant Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked;
            this.setState({[target.name]: value})
        } else {
            let tenantVariablesCopy = this.state.tenantVariables;
            tenantVariablesCopy[evt.target.name] = value
            this.setState({ tenantVariables:  tenantVariablesCopy});
        }
      // let tenantVariablesCopy = this.state.tenantVariables;
      // tenantVariablesCopy[evt.target.name] = evt.target.value
      // this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleCheckBoxChange(evt){
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
        const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false});
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl){
              const imageUrl = res.imageUrl;
              if(imageUrl){
                  const tenantVariablesCopy = vm.state.tenantVariables;
                  tenantVariablesCopy[name_of_file] = imageUrl;
                  vm.setState({
                      tenantVariables: tenantVariablesCopy,
                  }, () => {
                      vm[name_of_file].value = ""
                  });
              } else {
                  swal({
                      title: 'Image cannot be uploaded',
                      text: 'There was some issue with the image upload, please reupload and try again',
                      type: 'warning',
                      confirmButtonText: 'Ok'
                  })
              }
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: 'There was some issue with the image upload, please reupload and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    openColorPicker(colorToChange){
        var oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        var colorToChange = this.state.colorToUpdate;
        var tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        // var colorToChange = this.state.colorToUpdate;
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            })
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            })
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            })
        }
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const backgroundImage = tenantVariables.backgroundImage || "";
        const topAnswerImage = tenantVariables.topAnswerImage || "";
        const frontLogoImage = tenantVariables.frontLogoImage || "";
        const sponsorLogo = tenantVariables.sponsorLogo || "";
        const topLeftImage = tenantVariables.topLeftImage || "";
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        const winningMessageImage = tenantVariables.winningMessageImage || "";
        const scoreboardBackgroundImage = tenantVariables.scoreboardBackgroundImage || "";
        const leftScoreboardBackground = tenantVariables.leftScoreboardBackground || "";
        const topOfScoreboardImage = tenantVariables.topOfScoreboardImage || "";
        const showScoreboardPercentages = tenantVariables.showScoreboardPercentages;
        const showFanPercentages = tenantVariables.showFanPercentages;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('first', 'Major')}>
                              <a href="#" aria-expanded="false" className="nav-link active" id="showfirstMajor">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('second', 'Major')}>
                              <a href="#" aria-expanded="true" className="nav-link" id="showsecondMajor">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Scoreboard Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('third', 'Major')}>
                              <a href="#" aria-expanded="true" className="nav-link" id="showthirdMajor">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOneMajor === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.toggleColors()}>
                                <a href="#" aria-expanded="false" className="nav-link active" id="showColors">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Colors</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.toggleColors()}>
                                <a href="#" aria-expanded="true" className="nav-link" id="showImages">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Images</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabTwoMajor === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.tripleToggleControl("first", "Minor")}>
                                <a href="#" aria-expanded="false" className="nav-link active" id="showfirstMinor">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Colors</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.tripleToggleControl("second", "Minor")}>
                                <a href="#" aria-expanded="true" className="nav-link" id="showsecondMinor">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Images</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.tripleToggleControl("third", "Minor")}>
                                <a href="#" aria-expanded="true" className="nav-link" id="showthirdMinor">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Timing/Sizes</span>
                                </a>
                            </li>
                        </ul>
                      <div style={{display: this.state.showTabOneMajor ? 'block' : 'none'}}>
                          <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Primary Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Background Color</label>
                                          <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                          <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("fanQuestionColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.fanQuestionColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="fanQuestionColor" style={{marginRight: 10}}>Question Color</label>
                                          <input id="fanQuestionColor" name="fanQuestionColor" type="text" className="form-control" value={this.state.tenantVariables.fanQuestionColor} onChange={this.handleChange} placeholder="#000"/>

                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Answer Button Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerBackgroundColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.answerBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="answerBackgroundColor" style={{marginRight: 10}}>Background Color</label>
                                          <input id="answerBackgroundColor" name="answerBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.answerBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.answerTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="answerTextColor" style={{marginRight: 10}}>Text Color</label>
                                          <input id="answerTextColor" name="answerTextColor" type="text" className="form-control" value={this.state.tenantVariables.answerTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerOutlineColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.answerOutlineColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="answerOutlineColor" style={{marginRight: 10}}>Outline Color</label>
                                          <input id="answerOutlineColor" name="answerOutlineColor" type="text" className="form-control" value={this.state.tenantVariables.answerOutlineColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("answerProgressBarClient")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.answerProgressBarClient, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="answerProgressBarClient" style={{marginRight: 10}}>Progress Bar Color</label>
                                          <input id="answerProgressBarClient" name="answerProgressBarClient" type="text" className="form-control" value={this.state.tenantVariables.answerProgressBarClient} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>

                              <div style={{height:'10px', width:'100%'}}/>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Answer Action Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("correctTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.correctTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="correctTextColor" style={{marginRight: 10}}>Correct Answer Color</label>
                                          <input id="correctTextColor" name="correctTextColor" type="text" className="form-control" value={this.state.tenantVariables.correctTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("incorrectTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.incorrectTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="incorrectTextColor" style={{marginRight: 10}}>Incorrect Answer Color</label>
                                          <input id="incorrectTextColor" name="incorrectTextColor" type="text" className="form-control" value={this.state.tenantVariables.incorrectTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Log Out Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Log Out Button Color</label>
                                          <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                        </div>
                          <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                              <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                                  <div className="form-group" style={{textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: backgroundImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              backgroundImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={backgroundImage} width="160" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="backgroundImage">Phone Background Image<br/>(1654px X 926px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: topAnswerImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              topAnswerImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={topAnswerImage} width="100" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="topAnswerImage">Above End Answers Logo<br/>(612px X 470px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="topAnswerImage" name="topAnswerImage" type="file" ref={input => {this.topAnswerImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topAnswerImage').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: frontLogoImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              frontLogoImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={frontLogoImage} width="150" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="frontLogoImage">Front Logo<br/>(620px X 732px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                      </div>
                                  </div>
                              </div>
                              <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                                  <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: sponsorLogo?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              sponsorLogo: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={sponsorLogo} width="140" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="sponsorLogo">Sponsor Logo<br/>(280px X 380px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="sponsorLogo" name="sponsorLogo" type="file" ref={input => {this.sponsorLogo = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('sponsorLogo').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: topLeftImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              topLeftImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={topLeftImage} width="100" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="topLeftImage">Top Logo<br/>(398px X 470px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="topLeftImage" name="topLeftImage" type="file" ref={input => {this.topLeftImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: winningMessageImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                          ...tenantVariables,
                                          winningMessageImage: null
                                      }})}>
                                              ❌
                                      </div>
                                      <img src={winningMessageImage} width="100" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="winningMessageImage">
                                          Winning Message Image<br/>(400px X 200px)
                                      </label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="winningMessageImage" name="winningMessageImage" type="file" ref={input => {this.winningMessageImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('winningMessageImage').click()} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabTwoMajor ? 'block' : 'none'}}>
                          <div style={{display: this.state.showTabOneMinor ? 'block': 'none'}}>
                              <div className="row">
                                  <div className="col-md-12">
                                      <h4>Scoreboard Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarBackgroundColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarBackgroundColor" style={{marginRight: 10}}>Progress Bar Background Color</label>
                                          <input id="progressBarBackgroundColor" name="progressBarBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarColor" style={{marginRight: 10}}>Progress Bar Color</label>
                                          <input id="progressBarColor" name="progressBarColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarColor} onChange={this.handleChange} placeholder="#000"/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarTextColor" style={{marginRight: 10}}>Progress Bar Text Color</label>
                                          <input id="progressBarTextColor" name="progressBarTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarQuestionTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarQuestionTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarQuestionTextColor" style={{marginRight: 10}}>Progress Bar Question Text Color</label>
                                          <input id="progressBarQuestionTextColor" name="progressBarQuestionTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarQuestionTextColor} onChange={this.handleChange} placeholder="#000"/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarEndWinColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarEndWinColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarEndWinColor" style={{marginRight: 10}}>Scoreboard Correct Color</label>
                                          <input id="progressBarEndWinColor" name="progressBarEndWinColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarEndWinColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarOutlineScoreboardColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.progressBarOutlineScoreboardColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="progressBarOutlineScoreboardColor" style={{marginRight: 10}}>Progress Bar Outline Color</label>
                                          <input id="progressBarOutlineScoreboardColor" name="progressBarOutlineScoreboardColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarOutlineScoreboardColor} onChange={this.handleChange} placeholder="#000"/>

                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div style={{display: this.state.showTabTwoMinor ? 'block' : 'none'}}>
                              <div className="admin-grid-container two-columns">
                                  <div style={{textAlign:'center'}}>
                                      <h4>Scoreboard With Top Image</h4>
                                      <h6>{process.env.REACT_APP_SCOREBOARD_TOP_LINK}</h6>
                                      <div style={{height:'10px', width:'100%'}}/>
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group" style={{textAlign:'center'}}>
                                                  <div style={{cursor:"pointer", display: scoreboardBackgroundImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                          ...tenantVariables,
                                                          scoreboardBackgroundImage: null
                                                      }})}>
                                                      ❌
                                                  </div>
                                                  <img src={scoreboardBackgroundImage} width="160" height="auto" alt=""/>
                                                  <br/>
                                                  <label htmlFor="scoreboardBackgroundImage">Scoreboard Background Image<br/>(1654px X 926px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}} id="scoreboardBackgroundImage" name="scoreboardBackgroundImage" type="file" ref={input => {this.scoreboardBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scoreboardBackgroundImage').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-group" style={{textAlign:'center'}}>
                                                  <div style={{cursor:"pointer", display: topOfScoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                          ...tenantVariables,
                                                          topOfScoreboardImage: null
                                                      }})}>
                                                      ❌
                                                  </div>
                                                  <img src={topOfScoreboardImage} width="160" height="auto" alt=""/>
                                                  <br/>
                                                  <label htmlFor="topOfScoreboardImage">Top Of Scoreboard Image<br/>(1191px X 200px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}} id="topOfScoreboardImage" name="topOfScoreboardImage" type="file" ref={input => {this.topOfScoreboardImage = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topOfScoreboardImage').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div style={{textAlign:'center'}}>
                                      <h4>Scoreboard With Left Image</h4>
                                      <h6>{process.env.REACT_APP_SCOREBOARD_SIDE_LINK}</h6>
                                      <div style={{height:'10px', width:'100%'}}/>
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group" style={{textAlign:'center'}}>
                                                  <div style={{cursor:"pointer", display: leftScoreboardBackground?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                          ...tenantVariables,
                                                          leftScoreboardBackground: null
                                                      }})}>
                                                      ❌
                                                  </div>
                                                  <img src={leftScoreboardBackground} width="160" height="auto" alt=""/>
                                                  <br/>
                                                  <label htmlFor="leftScoreboardBackground">Scoreboard Background Image<br/>(1654px X 926px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}} id="leftScoreboardBackground" name="leftScoreboardBackground" type="file" ref={input => {this.leftScoreboardBackground = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftScoreboardBackground').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-group" style={{textAlign:'center'}}>
                                                  <div style={{cursor:"pointer", display: leftOfScoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                          ...tenantVariables,
                                                          leftOfScoreboardImage: null
                                                      }})}>
                                                      ❌
                                                  </div>
                                                  <img src={leftOfScoreboardImage} width="160" height="auto" alt=""/>
                                                  <br/>
                                                  <label htmlFor="leftOfScoreboardImage">Left Of Scoreboard Image<br/>(1821px X 2988px)</label>
                                                  <div className="form-group">
                                                      <input style={{display:'none'}} id="leftOfScoreboardImage" name="leftOfScoreboardImage" type="file" ref={input => {this.leftOfScoreboardImage = input; }} onChange={this.handleImageChange}/>
                                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftOfScoreboardImage').click()} />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div style={{display: this.state.showTabThreeMinor ? 'block' : 'none'}}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Timing</h4>
                                      <div className="form-inline">
                                          <label htmlFor="questionDisplayLength" style={{marginRight: 10}}>Question Show Length (secs)</label>
                                          <input id="questionDisplayLength" name="questionDisplayLength" type="number" className="form-control" value={this.state.tenantVariables.questionDisplayLength} onChange={this.handleChange} placeholder="5"/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <label htmlFor="answerDisplayLength" style={{marginRight: 10}}>Correct Answer Show Length (secs)</label>
                                          <input id="answerDisplayLength" name="answerDisplayLength" type="number" className="form-control" value={this.state.tenantVariables.answerDisplayLength} onChange={this.handleChange} placeholder="5"/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Top Image Scoreboard Sizes</h4>
                                      <div className="form-inline">
                                          <label htmlFor="topScoreboardQuestionSize" style={{marginRight: 10, marginLeft: 10}}>Question Size</label>
                                          <input id="topScoreboardQuestionSize" name="topScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.topScoreboardQuestionSize} onChange={this.handleChange} placeholder="44"/>
                                      </div>
                                      <h4>Left Image Scoreboard Sizes</h4>
                                      <div className="form-inline">
                                          <label htmlFor="leftScoreboardQuestionSize" style={{marginRight: 10, marginLeft: 10}}>Question Size</label>
                                          <input id="leftScoreboardQuestionSize" name="leftScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.leftScoreboardQuestionSize} onChange={this.handleChange} placeholder="44"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="row" style={{display:process.env.REACT_APP_FIREBASE_DOMAIN === "trivia-dev-faa4f.firebaseapp.com" ? "" : "none"}}>
                                  <div className="col-md-6">
                                      <h4>Zoom</h4>
                                      <div className="form-inline">
                                          <label htmlFor="zoomScoreboard" style={{marginRight: 10}}>Zoom On Custom Scoreboard (percent)</label>
                                          <input id="zoomScoreboard" name="zoomScoreboard" type="string" className="form-control" value={this.state.tenantVariables.zoomScoreboard} onChange={this.handleChange} placeholder="45%"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabThreeMajor ? 'block' : 'none'}}>
                          <div className="form-group">
                              <label htmlFor="pageTitle">Page Title:</label>
                              <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Trivia"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="playingTextHeader">Header Playing Text:</label>
                              <input id="playingTextHeader" name="playingTextHeader" type="text" className="form-control" value={this.state.tenantVariables.playingTextHeader} onChange={this.handleChange} placeholder="Next game starts during"/>
                          </div>
                        <div className="form-group">
                          <label htmlFor="playingText">Playing Text:</label>
                          <input id="playingText" name="playingText" type="text" className="form-control" value={this.state.tenantVariables.playingText} onChange={this.handleChange} placeholder="2nd Quarter"/>
                        </div>
                          <div className="form-group">
                              <label htmlFor="gameOverHeader">Game Over Header Text:</label>
                              <input id="gameOverHeader" name="gameOverHeader" type="text" className="form-control" value={this.state.tenantVariables.gameOverHeader} onChange={this.handleChange} placeholder="Answers are up!"/>
                          </div>
                          <div className="form-group">
                              <label htmlFor="gameOverBody">Game Over Body Text:</label>
                              <input id="gameOverBody" name="gameOverBody" type="text" className="form-control" value={this.state.tenantVariables.gameOverBody} onChange={this.handleChange} placeholder="Sorry you missed answering before the game ended! Come back next game to play again."/>
                          </div>
                          <div className="form-group row mb-3">
                              <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On The Users Phone</label>
                              <div className="col-md-9" onChange={this.handleChange}>
                                  <input type="radio" value="all" name="showFanPercentages" checked={showFanPercentages === "all"}/> <span style={{marginRight:10, fontSize:10}}>The Entire Game</span>
                                  <input type="radio" value="parttime" name="showFanPercentages" checked={showFanPercentages === "parttime"}/> <span style={{marginRight:10, fontSize:10}}>After The Game Ends</span>
                                  <input type="radio" value="never" name="showFanPercentages" checked={showFanPercentages === "never"}/> <span style={{fontSize:10}}>Never</span>
                              </div>
                          </div>
                          <div className="form-group row mb-3">
                              <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On The Scoreboard</label>
                              <div className="col-md-9" onChange={this.handleChange}>
                                  <input type="radio" value="all" name="showScoreboardPercentages" checked={showScoreboardPercentages === "all"}/> <span style={{marginRight:10, fontSize:10}}>The Entire Game</span>
                                  <input type="radio" value="parttime" name="showScoreboardPercentages" checked={showScoreboardPercentages === "parttime"}/> <span style={{marginRight:10, fontSize:10}}>After The Game Ends</span>
                                  <input type="radio" value="never" name="showScoreboardPercentages" checked={showScoreboardPercentages === "never"}/> <span style={{fontSize:10}}>Never</span>
                              </div>
                          </div>
                          <div className="form-group">
                              <label htmlFor="advancedTextChanges">Advanced Text Changes</label>
                              <br/>
                              <input type="checkbox" checked={this.state.advancedTextChanges} id="advancedTextChanges" name="advancedTextChanges" onChange={this.handleCheckBoxChange}/>
                          </div>
                          {this.state.advancedTextChanges &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="globalPrizeWonHeader">Global Prize Won Header:</label>
                                    <input id="globalPrizeWonHeader" name="globalPrizeWonHeader" type="text" className="form-control" value={this.state.tenantVariables.globalPrizeWonHeader} onChange={this.handleChange} placeholder="You Win!"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="globalPrizeWonBody">Global Prize Body:</label>
                                    <input id="globalPrizeWonBody" name="globalPrizeWonBody" type="text" className="form-control" value={this.state.tenantVariables.globalPrizeWonBody} onChange={this.handleChange} placeholder="Check your inbox for your prize!"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="globalNoPrizeHeader">Global No Prize Header:</label>
                                    <input id="globalNoPrizeHeader" name="globalNoPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.globalNoPrizeHeader} onChange={this.handleChange} placeholder="Nice Job!"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="globalNoPrizeBody">Global No Prize Body:</label>
                                    <input id="globalNoPrizeBody" name="globalNoPrizeBody" type="text" className="form-control" value={this.state.tenantVariables.globalNoPrizeBody} onChange={this.handleChange} placeholder="Unfortunately you didn't win a prize!"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="globalNoneCorrectPrizeHeader">Global None Correct Prize Header:</label>
                                    <input id="globalNoneCorrectPrizeHeader" name="globalNoneCorrectPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.globalNoneCorrectPrizeHeader} onChange={this.handleChange} placeholder="Nice Job!"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="globalNoneCorrectPrizeBody">Global None Correct Prize Body:</label>
                                    <input id="globalNoneCorrectPrizeBody" name="globalNoneCorrectPrizeBody" type="text" className="form-control" value={this.state.tenantVariables.globalNoneCorrectPrizeBody} onChange={this.handleChange} placeholder="Unfortunately you didn't win a prize!"/>
                                </div>
                            </>
                          }
                          <div className="form-group">
                              <label htmlFor="showAdvancedSettings">Advanced</label>
                              <br/>
                              <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleCheckBoxChange}/>
                          </div>
                          {this.state.advanced &&
                              <>
                                <div className="form-group">
                                    <label htmlFor="formHeaderText">Form header Text:</label>
                                    <input id="formHeaderText" name="formHeaderText" type="text" className="form-control" value={this.state.tenantVariables.formHeaderText} onChange={this.handleChange} placeholder="Fill out the form for a chance to win a prize!"/>
                                </div>
                              </>
                          }
                      </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpTeamVariables;
